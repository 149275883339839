import ContentBoundary from '@commons/ContentBoundary';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useState } from 'react';
import RoundSearchIcon from '@src_theme/icons/RoundSearch';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from '@i18n';
import { GRAY_500 } from '@root/src/theme/colors';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';
import dynamic from 'next/dynamic';
import BrowseModal from '@common_searchmodal';
import ShoppingAtSkeleton from '@modules/commons/ShoppingAt/skeleton';
import NotificationBellSkeleton from '@modules/notification/plugins/NotificationBell/components/skeleton';
import useStyles from './style';

const imgUrl = (url) => `${getThumborUrl()}/unsafe/0x0/filters:format(webp):quality(100)/${url}`;

const AlertCoupon = dynamic(() => import('@modules/home/pages/default/components/AlertCoupon'), { ssr: true });
const AlertPromo = dynamic(() => import('@modules/home/pages/default/components/AlertPromo'), { ssr: true });
const ShoppingAt = dynamic(() => import('@modules/commons/ShoppingAt'), {
    loading: () => <ShoppingAtSkeleton />,
    ssr: false,
});
const NotificationBell = dynamic(() => import('@modules/notification/plugins/NotificationBell'), {
    loading: () => <NotificationBellSkeleton />,
    ssr: false,
});
const StoreCreditAlert = dynamic(() => import('@modules/storecredit/pages/default/components/StoreCreditAlert'), { ssr: true });

const SearchBar = withStyles({
    root: {
        justifyContent: 'space-between',
        flexGrow: 1,
        borderRadius: 9999,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: 4,
        paddingLeft: 12,
        width: 0, // to make it shrink
        height: 32,
        '& > span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingRight: 4,
            color: GRAY_500,
        },
        '& > svg': {
            flexShrink: 0,
        },
    },
})(ButtonBase);

export default function MobileHeader({ storeConfig, isLogin, ...other }) {
    const [openModal, setOpenModal] = useState(false);
    const styles = useStyles();
    const handleOpenModal = (val) => setOpenModal(val);
    const logoUrl = `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`;
    const { t } = useTranslation(['home']);

    return (
        <div className={styles.mobileHeader}>
            {isLogin ? <AlertCoupon storeConfig={storeConfig} isLogin={isLogin} {...other} /> : null}
            <AlertPromo storeConfig={storeConfig} {...other} />
            {isLogin ? <StoreCreditAlert t={t} /> : ''}
            <ContentBoundary wrapperProps={{ className: styles.mobileTopNav }}>
                <BrowseModal storeConfig={storeConfig} open={openModal} setOpenModal={handleOpenModal} />
                <img src={imgUrl(logoUrl)} alt="logo" className={styles.imgLogoMobile} width={87} height={32} />
                <SearchBar onClick={() => handleOpenModal(true)}>
                    <span>{t('home:searchProduct')}</span>
                    <RoundSearchIcon width={24} />
                </SearchBar>
                <NotificationBell withLink />
            </ContentBoundary>
            <ContentBoundary className={styles.mobileShoppingAtContainer}>
                <ShoppingAt />
            </ContentBoundary>
        </div>
    );
}
